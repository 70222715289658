import $ from "jquery";

export default class Http {
    static ajax(url, params, method, success_func, failed_func, ignore_error) {
        if (failed_func === undefined)
            failed_func = rs => {
                swal("Erro", rs.msg, "error");
            };
        $.ajax({
            type: method,
            url: url,
            data: params,
            dataType: 'json',
            success: data => {
                if (data.code === 'success')
                    success_func(data.data);
                else if (data.code === 'login_to_operate') {
                    if (url.startsWith('/user/'))
                        location.href = '/user/user_sessions/new'
                    else
                        location.href = '/admin/admin_sessions/new'
                } else
                    failed_func({code: data.code, msg: data.msg});

            },
            error: data => {
                failed_func({code: 'error', msg: 'System error'});
            }
        });
    }

    static submit_form(url, form_id, success_func, failed_func) {
        if (failed_func === undefined)
            failed_func = rs => {
                swal("Error", rs.msg, "error");
            };
        let data = $('#' + form_id).serialize();
        $.ajax({
            type: 'post',
            url: url,
            data: data,
            dataType: 'json',
            success: data => {
                if (data.code === 'success')
                    success_func(data.data);
                else if (data.code === 'login_to_operate') {
                    if (url.startsWith('/user/'))
                        location.href = '/user/user_sessions/new'
                    else
                        location.href = '/admin/admin_sessions/new'
                } else {
                    failed_func({code: data.code, msg: data.msg});
                }
            },
            error: data => {
                failed_func({code: 'error', msg: 'System error'});
            }
        });
    }

    static upload_files(url, form_id, success_func, failed_func) {
        if (failed_func === undefined)
            failed_func = rs => {
                swal("Error", rs.msg, "error");
            };
        let form_data = new FormData();
        $.each($('#' + form_id).serializeArray(), (i, item) => {
            form_data.append(item.name, item.value);
        });
        $.each($('#' + form_id + ' input[type="file"]'), (i, ui) => {
            let ele = $(ui);
            if (ele.val() !== '')
                form_data.append(ele.attr('name'), $(ui)[0].files[0]);
        });
        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("post", url);
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                let data = JSON.parse(xhr.responseText);
                if (data.code === 'success')
                    success_func(data.data);
                else if (data.code === 'login_to_operate') {
                    if (url.startsWith('/user/'))
                        location.href = '/user/user_sessions/new'
                    else
                        location.href = '/admin/admin_sessions/new'
                }
                else
                    failed_func(data);
            }
        }
        xhr.send(form_data);
    }

    static get(url, params, success_func, failed_func, ignore_error) {
        Http.ajax(url, params, 'get', success_func, failed_func, ignore_error);
    }

    static post(url, params, success_func, failed_func) {
        Http.ajax(url, params, 'post', success_func, failed_func);
    }

    static put(url, params, success_func, failed_func) {
        params['_method'] = 'put';
        Http.ajax(url, params, 'post', success_func, failed_func);
    }

    static delete(url, params, success_func, failed_func) {
        params['_method'] = 'delete';
        Http.ajax(url, params, 'post', success_func, failed_func);
    }
}